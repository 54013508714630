import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";
import { getWebMenu, setupAxiosInterceptor } from '../services/dataServices';
import logoImage from "../assets/images/Sadar-Manzil-Logo.svg"
import phoneImage from "../assets/images/phone.svg"
import emailImage from "../assets/images/email.svg"



function UnderMaintenance({ setIsServerDown }) {
  const navigate = useNavigate();

  useEffect(() => {
    setupAxiosInterceptor(setIsServerDown);
    
    const intervalId = setInterval(async () => {
      const data = await getWebMenu('maintainence');
      if (data) {
        // Redirect to the home page upon successful data retrieval
        navigate('/');
        clearInterval(intervalId); // Clear the interval to stop polling
      }
    }, 5000); // Poll every 5 seconds

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [setIsServerDown, navigate]);

  return (
    <>
     <section>
            <div className="getting-ready kanifushi-getting-ready">
                <div className="getting-ready-inner">
                    <div className="project-logo">
                        <img width={260} src={logoImage} alt=""/>
                    </div>
                    <div className="getting-ready-content">
                        <h1>getting Ready!</h1>
                        <p>We’re catching a wave of updates, but you don’t have to wait to book your next tropical beachside stay!</p>
                        <a href="https://reservations.atmosphere-kanifushi.com/book/dates-of-stay" target="_blank" className="book-now-btn">book now</a>
                    </div>
                    <div className="footer-info kanifushi">
                        <a href="+9606620066"><span><img src={phoneImage} alt=""/></span> +960 6620 066</a>
                        <a href="mailto:sales@atmosphere-kanifushi.com"><span><img src={emailImage} alt=""/></span> sales@atmosphere-kanifushi.com</a>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default UnderMaintenance;
