import React from "react";
// import AtmosphereVideo from '../assets/video/atmosphere-kanifushi.mp4'
import AtmosphereVideoCover from "../assets/images/atmosphere-living-banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import Image from "./Image";

function HomeVideo({ AtmosphereVideo }) {
  const homeSliderSettings = {
    arrows: true,
    dots: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false,
    touchMove: true,
    accessibility: true,
    responsive:[
      {
          breakpoint: 767,
          settings: {
            autoplaySpeed: 3000,
          }
      }
  ]
  };
  console.log("AtmosphereVideo",AtmosphereVideo)
  console.log("AtmosphereVideoCover",AtmosphereVideoCover)
  return (
    <>
      {/* <section>
        <div className="atmosphere-hero-video position-relative">
          <video
            id="BgVideo"
            className="w-100 d-flex"
            poster={AtmosphereVideoCover}
            preload="auto"
            loop
            muted
            autoPlay
            playsInline
          >
           {AtmosphereVideo &&<source
              preload="auto"
              src={AtmosphereVideo}
              type="video/mp4"
            />} 
          </video>
          <div className="scroll-down d-flex justify-content-center position-absolute start-0 end-0 z-1 flex-column text-center">
            <a href="#BookNow" className="text-white">
              <span className="arrow1">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
              <span className="arrow2">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
              <span className="arrow3">
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
              </span>
            </a>
          </div>
        </div>
      </section> */}
      <section>
          <div className='sadar-home-slider slide-default-actions overflow-hidden'>
            <Slider  {...homeSliderSettings} className='large-slides slick-arrow-default slide-dots defaultarrow-style slide-padding'>
              {AtmosphereVideo?.map((data,index)=>(
                <div className='slide-item' key={index}>
                  <Image 
                  className='w-100' 
                  src={data?.slideImg}
                  // fallback={AtmosphereVideoCover  }
                   alt={data?.altTag} />
                </div>
              ))}
            </Slider>

            </div>
        </section>
    </>
  );
}

export default HomeVideo;
