import React from "react";
import PlanTabItem from "./PlanTabItem";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import featureImgOne from "../assets/images/dinning-experience.jpg";
import featureImgTwo from "../assets/images/beverage-feature.jpg";
import featureImgThree from "../assets/images/speciality-fine-dining-feature.jpg";
import featureImgFour from "../assets/images/arrival-departure-feature.jpg";
import featureImgFive from "../assets/images/activities-advantures-feature.jpg";
import featureImgSix from "../assets/images/spa-wellness-feature.jpg";
import tabIconOne from "../assets/images/spoon-fork.svg";
import tabIconTwo from "../assets/images/beverages.svg";
import tabIconThree from "../assets/images/fine-dinning.svg";
import tabIconFour from "../assets/images/airplane.svg";
import tabIconFive from "../assets/images/adventures.svg";
import tabIconSix from "../assets/images/spa-wellness.svg";
import Image from "./Image";
const ExplorePlanTabs = ({ PlanData }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    
    setActiveTab(index);
  };
  const tabsArray = [
    {
      coverId: 0,
      tabFeatureImage: featureImgOne,
      name: "Dining Experience",
      tabIcon: tabIconOne,
      planFaqHeading: "Dining Experiences",
      accordionData: [
        {
          planTabHeading: "The Spice",
          planEventKey: 0,
          planTabText:
            "Our main, all-day dining restaurant offers sumptuous buffet-style meals. It features live cooking stations, grills and delightful desserts.",
        },
        {
          planTabHeading: "Ceylon Bliss",
          planEventKey: 1,
          planTabText:
            "Situated in the middle of the island amidst lush vegetation, Ceylon Bliss offers a delicious selection of Sri Lankan street food.",
        },
        {
          planTabHeading: "The Sunset Pool Bar",
          planEventKey: 2,
          planTabText:
            "A fantastic pool bar by the day serving light meals and snacks from à la carte lunch menu. It offers an extensive drinks menu.",
        },
        {
          planTabHeading: "THE LIQUID ",
          planEventKey: 3,
          planTabText:
            "This poolside bar serves a range of refreshing cocktails and mocktails with freshly fried fish and chips in the evening.",
        },
      ],
    },
    {
      coverId: 1,
      tabFeatureImage: featureImgTwo,
      name: "Beverages",
      tabIcon: tabIconTwo,
      planFaqHeading: "Beverages",
      accordionData: [
        {
          planTabHeading: "PREMIUM DRINKS",
          planEventKey: 4,
          planTabText:
            "Premium spirits, beer, and liqueur are available at The Spice, The Liquid, The Sunset, Just Veg, Pier Six and The Lantern Bar.",
        },
        {
          planTabHeading: "SPIRITS, COCKTAILS AND BEERS",
          planEventKey: 5,
          planTabText:
            "A set menu featuring unlimited cocktails and mocktails; signature drinks, juices, and soft drinks; a selection of tea and coffee.",
        },
        {
          planTabHeading: "STOCKED MINIBAR",
          planEventKey: 6,
          planTabText:
            "We replenish the minbar in your villa daily with wines, beers, soft drinks and snacks.",
        },
        {
          planTabHeading: "DELICIOUS WINES",
          planEventKey: 7,
          planTabText:
            "The Atmosphere THE BEGUM PLAN™ Wine Menu features a wide selection of premium and sparkling wines from the world over.",
        },
      ],
    },
    {
      coverId: 2,
      tabFeatureImage: featureImgThree,
      name: "SPECIALTY FINE DINING",
      tabIcon: tabIconThree,
      planFaqHeading: "Specialty Fine Dining",
      accordionData: [
        {
          planTabHeading: "THE LANTERN BAR ",
          planEventKey: 8,
          planTabText:
            "A charming overwater bar serving vegetarian platters reimagining iconic Asian dishes and healing elixirs prepared by Ayurvedic doctors.",
        },
        {
          planTabHeading: "THE SUNSET",
          planEventKey: 9,
          planTabText:
            "An à la carte fine-dining restaurant serving Asian and Mediterranean cuisine with a focus on expertly grilled meats.",
        },
        {
          planTabHeading: "THE PIER SIX",
          planEventKey: 10,
          planTabText:
            "An overwater fine dining restaurant offering a sweeping choice of seafood specialities and Japanese Teppanyaki grill options.",
        },
        {
          planTabHeading: "TEPPANYAKI GRILL at THE PIER SIX",
          planEventKey: 11,
          planTabText:
            "An authentic Japanese Teppanyaki grill experience in an over-water restaurant designed in the shape of a manta ray.",
        },
      ],
    },
    {
      coverId: 3,
      tabFeatureImage: featureImgFour,
      name: "Arrival & Departure",
      tabIcon: tabIconFour,
      planFaqHeading: "Arrival & Departure",
      accordionData: [
        {
          planTabHeading: "ARRIVAL",
          planEventKey: 12,
          planTabText:
            "The Atmosphere Kanifushi team receives you at the airport and escorts you to the Trans Maldivian Airways VIP lounge.",
        },
        {
          planTabHeading: "JOURNEY",
          planEventKey: 13,
          planTabText:
            "Kanifushi Island is a 35-minute seaplane journey from the Velena International Airport, Male’.",
        },
        {
          planTabHeading: "DEPARTURE",
          planEventKey: 14,
          planTabText:
            "After a seaplane ride to the Seaplane Terminal in Male’, our team will escort you to the Velena International Airport, Malé.",
        },
        {
          planTabHeading: "TIMINGS",
          planEventKey: 15,
          planTabText:
            "Check-in time is at 2 PM and the check-out time is NOON.",
        },
      ],
    },
    {
      coverId: 4,
      tabFeatureImage: featureImgFive,
      name: "Activities & Adventures",
      tabIcon: tabIconFive,
      planFaqHeading: "Activities & Adventures",
      accordionData: [
        {
          planTabHeading: "UNDERWATER",
          planEventKey: 16,
          planTabText:
            "Discover the magical underwater world of the Maldives and its rich marine life through snorkelling or diving.",
        },
        {
          planTabHeading: "WATER SPORTS",
          planEventKey: 17,
          planTabText:
            "Set sail on an aquatic excursion with complimentary non-motorized water sports during your entire stay.",
        },
        {
          planTabHeading: "ISLAND HOPPING",
          planEventKey: 18,
          planTabText:
            "Explore the beauty of Maldives with island hopping to immerse yourself in the local culture and traditions.",
        },
        {
          planTabHeading: "SUNSET CRUISE",
          planEventKey: 19,
          planTabText:
            "Set off on the Dhoni on a sunset cruise where you bid farewell to yet another magical day at Kanifushi.",
        },
      ],
    },
    {
      coverId: 5,
      tabFeatureImage: featureImgSix,
      name: "SPA & WELLNESS",
      tabIcon: tabIconSix,
      planFaqHeading: "spa & wellness",
      accordionData: [
        {
          planTabHeading: "4 - 7 Nights",
          planEventKey: 20,
          planTabText: "Enjoy ONE 45-minute spa treatment",
        },
        {
          planTabHeading: "8 - 11 Nights",
          planEventKey: 21,
          planTabText: "Delight in TWO 45-minute spa treatments",
        },
        {
          planTabHeading: "12 - 15 Nights",
          planEventKey: 22,
          planTabText: "Experience THREE 45-minute spa treatments",
        },
        {
          planTabHeading: "16 - 19 Nights",
          planEventKey: 23,
          planTabText: "Immerse in FOUR 45-minute spa treatments",
        },
      ],
    },
  ];

  
  const allPlanData = PlanData && PlanData[0]?.subSectionData;
  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
        allPlanData={allPlanData}
      >
        {/* generating an array to loop through it  */}

        {PlanData &&
          PlanData[0]?.subSectionData.map((subData, index) => {
            const title =
              subData?.elements?.filter(
                (item) => item.field_title_code === "TITLE"
              )[0]?.data ?? null;
              const icon =
              subData?.elements?.filter(
                (item) => item.field_title_code === "ICON"
              )[0]?.data ?? null;

            return (
              <Tab
                key={index}
                className={`item ${
                  activeTab === index
                    ? "active text-uppercase d-flex align-items-center"
                    : "text-uppercase d-flex align-items-center"
                }`}
              >
                <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
                  {/* <img src={icon} alt="" title="" /> */}
                  <Image className="" src={icon} alt="" />
                </span>
                <span className="tab-name">{title}</span>
              </Tab>
            );
          })}
      </Tabs>
      {PlanData &&
        PlanData[0]?.subSectionData.map((SubChlidAccordionData, index) => {
          const planFaqHeading =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "TITLE"
            )[0]?.data ?? null;
          const tabFeatureImage =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "MAINIMAGE"
            )[0]?.data ?? null;

            const imageAltTag =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "MAINIMAGE"
            )[0]?.img_alt_tag ?? null;
       
          return (
            <TabScreen
              key={index}
              activeTab={activeTab}
              index={index}

              // You can add animation with adding a custom class
            >
              <PlanTabItem
              imageAltTag={imageAltTag}
                tabFeatureImage={tabFeatureImage}
                planFaqHeading={planFaqHeading}
                accordionData={SubChlidAccordionData}
              />
            </TabScreen>
          );
        })}
    </>
  );
};

export default ExplorePlanTabs;
