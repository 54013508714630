
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getPagesSlug } from "../services/dataServices";
import Loader from "../components/Loader";
import Home from "../pages/home/Home";
import OurSubBrandsPage from "../pages/OurSubBrandsPage";
import ContactUs from "../pages/contact/ContactUs";
import SustainabilityPage from "../pages/theSustainability/SustainabilityPage";
import Offers from "../pages/Offers/Offers";
import OfferDetail from "../pages/Offers/OfferDetail";
import Media from "../pages/mediaCenter/Media";
import OurVillas from "../pages/theVillas/OurVillas";
import DiningList from "../pages/theDining/DiningList";
import AkiraSpa from "../pages/akiraSpa/AkiraSpa";
import Experiences from "../pages/theExperience/Experiences";
import OurGallery from "../pages/theGallery/OurGallery";
import OurPlans from "../pages/thePlan/OurPlans";
import PressReleaseDetails from "../pages/mediaCenter/PressReleaseDetails";
import DiningDetails from "../pages/theDining/DiningDetails";
import VillaDetails from "../pages/theVillas/VillaDetails";
import TermsConditions from "../pages/policy/TermsConditions";
import PrivacyPolicy from "../pages/policy/PrivacyPolicy";
import PageNotFound from "../components/PageNotFound";

function AllRoutes({ errorPage, setErrorPage }) {
  const queryString = window.location.search;

  // Create a URLSearchParams object to parse the query string
  const urlParams = new URLSearchParams(queryString);

  // Get values from the query string
  const isPreview = urlParams.get("ispreview");
  const userId = urlParams.get("uid");
  const sectionId = urlParams.get("sid");

  const getAllRoutes = useQuery("getPagesSlug", getPagesSlug);
  const { isLoading, isSuccess } = getAllRoutes;

  const slugOfKHome =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SHOME")[0]
      ?.slug ?? null;

  const slugOfPlan =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SPLAN")[0]
      ?.slug ?? null;

  const slugOfDining =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SDINING")[0]
      ?.slug ?? null;

  const slugOfAkiriSpa =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SAKIRASPA")[0]
      ?.slug ?? null;

  const slugOfContactUs =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SCONTACTUS"
    )[0]?.slug ?? null;

  const slugOfSustainbility =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SSUSTAINABILITY"
    )[0]?.slug ?? null;

  const slugOfOffers =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SOFFERS")[0]
      ?.slug ?? null;

  const slugOfExperience =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SEXPERIENCES"
    )[0]?.slug ?? null;

  const slugOfGallery =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SGALLERY")[0]
      ?.slug ?? null;

  const slugOfMedia =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SMEDIA")[0]
      ?.slug ?? null;

  const slugOfOurVillas =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "SVILLAS")[0]
      ?.slug ?? null;

  const slugOfTermsConditon =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "STERMCONDITION"
    )[0]?.slug ?? null;

  const slugOfPrivacyPolicy =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SPRIVACYPOLICY"
    )[0]?.slug ?? null;

  const slugOfSpaOffers =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SSPAOFFERS"
    )[0]?.slug ?? null;
  const slugOfDiningOffers =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SDININGOFFERS"
    )[0]?.slug ?? null;
  const slugOfRoomOffers =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "SROOMOFFERS"
    )[0]?.slug ?? null;

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <Home
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            villaSlug={slugOfOurVillas}
            planSlug={slugOfPlan}
            offersSlug={slugOfOffers}

            sustainibilitySlug={slugOfSustainbility}
            slugOfGallery={slugOfGallery}

            slugOfDiningOffers={slugOfDiningOffers}
            slugOfRoomOffers={slugOfRoomOffers}
            slugOfSpaOffers={slugOfSpaOffers}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfPlan}`}
        element={
          <OurPlans
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfDining}`}
        element={
          <DiningList
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            slugOfGallery={slugOfGallery}
            slugOfDining={slugOfDining}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfAkiriSpa}`}
        element={
          <AkiraSpa
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            slugOfGallery={slugOfGallery}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfContactUs}`}
        element={
          <ContactUs
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfSustainbility}`}
        element={
          <SustainabilityPage
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfSpaOffers}`}
        element={
          <Offers
            section_code={"SPAOFFERS"}
            offerCatCode={"SPAOFFER"}
            offerPageCode={"SSPAOFFERS"}
            slugOfOffers={slugOfSpaOffers}
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          // slugOfOffers={slugOfOffers}
          />
        }
      ></Route>

      <Route
        path={`/${slugOfRoomOffers}`}
        element={
          <Offers

            section_code={"ROOMOFFERS"}
            offerCatCode={"ROOMOFFER"}
            offerPageCode={"SROOMOFFERS"}
            slugOfOffers={slugOfRoomOffers}
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          // slugOfOffers={slugOfOffers}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfDiningOffers}`}
        element={
          <Offers

            section_code={"DININGOFFERS"}
            offerCatCode={"DININGOFFER"}
            offerPageCode={"SDININGOFFERS"}
            slugOfOffers={slugOfDiningOffers}
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          // slugOfOffers={slugOfOffers}
          />
        }
      ></Route>
      <Route
        path={`${slugOfDiningOffers}/:offerId`}
        element={
          <OfferDetail
            setErrorPage={setErrorPage}
            slugOfOffers={slugOfDiningOffers}
          />
        }
      />
      <Route
        path={`${slugOfSpaOffers}/:offerId`}
        element={
          <OfferDetail
            setErrorPage={setErrorPage}
            slugOfOffers={slugOfSpaOffers}
          />
        }
      />
      <Route
        path={`${slugOfRoomOffers}/:offerId`}
        element={
          <OfferDetail
            setErrorPage={setErrorPage}
            slugOfOffers={slugOfRoomOffers}
          />

        }
      />

      <Route
        path={`/${slugOfExperience}`}
        element={
          <Experiences
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfGallery}`}
        element={
          <OurGallery
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            slugOfGallery={slugOfGallery}
          />
        }
      ></Route>
      <Route
        path={`/${slugOfMedia}`}
        element={
          <Media
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            slugOfMedia={slugOfMedia}
          />
        }
      ></Route>

      <Route
        path={`/${slugOfOurVillas}`}
        element={
          <OurVillas
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
            slugOfGallery={slugOfGallery}
            slugOfOurVillas={slugOfOurVillas}
          />
        }
      ></Route>
      <Route
        path={`${slugOfTermsConditon}`}
        element={
          <TermsConditions
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>

      <Route
        path={`${slugOfPrivacyPolicy}`}
        element={
          <PrivacyPolicy
            isPreview={isPreview}
            userId={userId}
            sectionId={sectionId}
          />
        }
      ></Route>

      <Route

        path={`${slugOfOurVillas}/:propertyId`}
        element={
          <VillaDetails
            slugOfOurVillas={slugOfOurVillas}
            setErrorPage={setErrorPage}
          />
        }
      ></Route>

      <Route path="/our-brands-page" element={<OurSubBrandsPage />}></Route>

      <Route
        path={`${slugOfMedia}/:websitePressReleaseId`}
        element={
          <PressReleaseDetails
            setErrorPage={setErrorPage}
            slugOfMedia={slugOfMedia}
          />
        }
      ></Route>

      <Route
        path={`/${slugOfDining}/:restaurantId`}
        element={
          <DiningDetails
            slugOfDining={slugOfDining}
            setErrorPage={setErrorPage}
          />
        }
      ></Route>

      <Route path="/offer-details" element={<OfferDetail />}></Route>
      <Route
        // path="/offer-details/:offerId"
        path={`/${slugOfOffers}/:offerId`}
        element={
          <OfferDetail
            slugOfOffers={slugOfOffers}
            setErrorPage={setErrorPage}
          />
        }
      ></Route>

      {slugOfOurVillas && (
        <Route
          path="/villas"
          element={<Navigate to={`/${slugOfOurVillas}`} />}
        />
      )}
      {slugOfContactUs && (
        <Route
          path="/faq"
          element={<Navigate to={`/${slugOfContactUs}`} />}
        />
      )}
      {slugOfKHome && (
        <Route path="/about-us" element={<Navigate to="/" />} />
      )}

      {isSuccess && (
        <Route
          path="/*"
          element={
            <PageNotFound errorPage={errorPage} setErrorPage={setErrorPage} />
          }
        ></Route>
      )}
    </Routes>
  );
}

export default AllRoutes;
