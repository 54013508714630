// import React, { useState } from 'react'

// const Image = ({src="", style={},alt="",title="",className="" ,fallback=""}) => {
  
//     const [loaded,setLoaded] = useState(false)
//     const [error, setError] = useState(false);
//     const urlParts = src?.split('/');
//     const baseURL = urlParts?.slice(0, -2).join('/') + '/';
//     const filename = urlParts?.[urlParts?.length - 1];
//     const blurredFilename = "thumbnails/blurred-" + filename;
//     const blurredURL = baseURL + blurredFilename;
//     const handleLoad = () => {
//         setLoaded(true)
//     }
//     const handleError = () => {
//         setError(true);
//         setLoaded(true)
//       };
//   return (
//     <>
//     {!error && (
//         <> 
//     <img className={`${className}`} src={loaded?src:blurredURL} alt={alt} onLoad={handleLoad} onError={handleError} title={title} style={style}/>
//     </>
//       )}
//       {error && (
//         <img
//           className={`${className} fallback`}
//           src={fallback}
//           alt=""
//           title=""
//           style={{
//             ...style,
//             position: 'relative',
//             opacity: 1,
//           }}
//         />
//       )}
//       </>
//   )
// }

// export default Image

// upper work is show images static if their is no image and if image not found in directory 



import React, { useState } from 'react'

const Image = ({src="", style={},alt="",title="",className="" }) => {
  
    const [loaded,setLoaded] = useState(false)
    const urlParts = src?.split('/');
    const baseURL = urlParts?.slice(0, -2).join('/') + '/';
    const filename = urlParts?.[urlParts?.length - 1];
    const blurredFilename = "thumbnails/blurred-" + filename;
    const blurredURL = baseURL + blurredFilename;
    const handleLoad = () => {
        setLoaded(true)
    }
  return (
      <img className={`${className}`} src={loaded?src:blurredURL} alt={alt} onLoad={handleLoad} onError={()=>setLoaded(true)} title={title} style={style}/>
  )
}

export default Image


// import React, { useState } from 'react';

// const Image = ({ src = "", style = {}, alt = "", title = "", className = "" }) => {
//   const [loaded, setLoaded] = useState(false);
//   const [error, setError] = useState(false);

//   if (!src) return null;  // Early return if src is empty

//   // Constructing blurred image URL
//   const urlParts = src.split('/');
//   const baseURL = urlParts.slice(0, -2).join('/') + '/';
//   const filename = urlParts[urlParts.length - 1];
//   const blurredURL = `${baseURL}thumbnails/blurred-${filename}`;

//   const handleLoad = () => {
//     setLoaded(true);
//   };

//   const handleError = () => {
//     setError(true);
//   };

//   return (
//     <div style={{ position: 'relative', ...style }}>
//       {!error && (
//         <>
//           <img
//             className={` ${className} ${loaded ? 'loaded' : 'loading'}`}
//             src={blurredURL}
//             alt={alt}
//             title={title}
//             style={{
//               ...style,
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               opacity: loaded ? 0 : 1,
//               transition: 'opacity 0.5s ease-in-out',
//             }}
//           />
//           <img
//             className={`${className} ${loaded ? 'loaded' : 'loading'}`}
//             src={src}
//             alt={alt}
//             onLoad={handleLoad}
//             onError={handleError}
//             title={title}
//             style={{
//               ...style,
//               position: 'relative',
//               opacity: loaded ? 1 : 0,
//               transition: 'opacity 0.5s ease-in-out',
//             }}
//           />
//         </>
//       )}
//       {error && (
//         <div className="error-placeholder" style={{ ...style }}>
//           {/* Placeholder for error, can be customized */}
//           <span>Image failed to load</span>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Image;

