import React, { useContext } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Helmet from "../../components/Helmet";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import ExperienceAwait from "../../components/ExperienceAwait";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Sustainability from "../../components/Sustainability";
import greenSustain from "../../assets/images/green-tree.jpg";
import greenBottomSustain from "../../assets/images/green-sustain-operations.jpg";
import { getPageData, getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import Image from "../../components/Image";
// import { MyContext } from "../../Contex/LoadingContex";
import BannerImage from "../../components/BannerImage";

function SustainabilityPage({ isPreview, userId, sectionId }) {
  const sustainheadingOne = ["A New Experience Awaits"];

  const sectionClass = "welcome-sustainability";

  const pageCode = "SSUSTAINABILITY";
  // const { setIsLoadingMain } = useContext(MyContext);

  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );

  const sustainAbilityImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "MAINBANNER" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ?? bannerCover;

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );

  const sustainAbilityData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MAINBANNER"
      )[0] ?? null
  );

  const sustainAbilityBannerimgAltTag =
    sustainAbilityData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? null;

  const experienceAwaitData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "PERSERVING"
      )[0] ?? null
  );

  const experienceAwaitIcon =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data;

  const experienceAwaitHeading =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;

  const para =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;
  const susPdf =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "SUSPDF"
    )[0]?.data ?? null;
  const envPdf =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ENVPDF"
    )[0]?.data ?? null;

  const greenGlobeData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "GREENGLOBAL"
      )[0] ?? null
  );
  const greenGlobeHeading =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;
  const greenGlobeLearnMoreLink =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === ""
    )[0]?.data ?? null;
  const greenGlobeImage =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "SECTIONIMAGE"
    )[0]?.data ?? null;
  const greenGlobeImageAltTag =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "SECTIONIMAGE"
    )[0]?.img_alt_tag ?? null;

  const greenGlobeDescription =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;
  const greenGlobeIcon =
    greenGlobeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data ?? null;

  const sustainableOperationData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_title === "Sustainable Operations"
      )[0] ?? null
  );
  const sustainableOperationHeading =
    sustainableOperationData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;
  const sustainableOperationLearnMoreLink =
    sustainableOperationData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === ""
    )[0]?.data ?? null;
  const sustainableOperationDescription =
    sustainableOperationData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;
  const sustainableOperationImage =
    sustainableOperationData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "SECTIONIMAGE"
    )[0]?.data ?? null;
  const sustainableOperationImageAltTag =
    sustainableOperationData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "SECTIONIMAGE"
    )[0]?.img_alt_tag ?? null;
  const greenInitiativeData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "GREENINITIATIVE"
      )[0] ?? null
  );

  const greenInitiativeTitle =
    greenInitiativeData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KTITLE"
    )[0]?.data ?? null;

  const sustainSection1 = greenInitiativeData?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "SECTION1"
  );

  const greenSustainImage =
    sustainSection1
      ?.map((subData) => subData.elements)?.[0]
      ?.filter((i) => i.field_title_code === "SUSIMG")?.[0]?.data ?? null;
  const heading1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;
  const icon1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;
  const description1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const heading2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;
  const icon2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;
  const description2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const sustainSection2 = greenInitiativeData?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "SECTION4"
  );
  const greenButtomSustainImage =
    sustainSection2
      ?.map((subData) => subData.elements)?.[0]
      ?.filter((i) => i.field_title_code === "SUSIMG")?.[0]?.data ?? null;

  const heading3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;
  const icon3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;
  const description3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const heading4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;
  const icon4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;
  const description4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const greenSustainImageAltTag =
    sustainSection1?.[0]?.elements?.find((i) => i.field_title_code === "SUSIMG")
      ?.img_alt_tag ?? null;
  const greenButtomSustainImageAltTag =
    sustainSection2?.[0]?.elements?.find((i) => i.field_title_code === "SUSIMG")
      ?.img_alt_tag ?? null;

  const images = [
    {
      sustainFeatureImg: `${icon1}`,
      sustainHeading: `${heading1}`,
      sustainPara: `${description1}`,
    },

    {
      sustainFeatureImg: `${icon2}`,
      sustainHeading: `${heading2}`,
      sustainPara: `${description2}`,
    },
  ];
  const bottomimages = [
    {
      sustainFeatureImg: `${icon3}`,
      sustainHeading: `${heading3}`,
      sustainPara: `${description3}`,
    },

    {
      sustainFeatureImg: `${icon4}`,
      sustainHeading: `${heading4}`,
      sustainPara: `${description4}`,
    },
  ];

  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  // if (pagesSectionData1.isLoading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (pagesSectionData1.isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => { setIsLoadingMain(false) };
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={sustainAbilityImage}
              title=""
              alt={sustainAbilityBannerimgAltTag}
              // onLoad={(event) => {
              //   event.target.src = sustainAbilityImage;
              // }}
              onLoad={handleImageLoad}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={sustainAbilityImage}
              title=""
              alt={sustainAbilityBannerimgAltTag}
              isDataIsLoading={pagesSectionData1.isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Sustainability
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Sustainability</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <div className="sustainability-page-wrap">
        <ExperienceAwait
          sectionClass={sectionClass}
          sustainheading={sustainheadingOne}
          heading={parse(`${experienceAwaitHeading}`)}
          image={experienceAwaitIcon}
          para={para}
          showLearnMore={false}
          visitLinks={true}
          showLargeheading={true}
          susPdf={susPdf}
          envPdf={envPdf}
        />

        <section>
          <div className="our-values sustainability-cards">
            <div className="container">
              <div className="row mt-lg-5 mt-4 position-relative">
                <div className="col-md-6">
                  <div className="feature-left-image position-relative">
                    {/* <img
                      className="w-100"
                      src={greenGlobeImage}
                      alt={greenGlobeImageAltTag}
                      title=""
                    /> */}

                    <Image
                      className="w-100"
                      src={greenGlobeImage}
                      alt={greenGlobeImageAltTag}
                      title=""
                    />
                    <div className="green-globe-icon position-absolute">
                      {/* <img src={greenGlobeIcon} alt="" title="" /> */}
                      <Image src={greenGlobeIcon} alt="" title="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-feature-wrap position-relative">
                    <div className="top-head-text">
                      <h4 className="georgia text-uppercase">
                        {greenGlobeHeading}
                      </h4>
                    </div>
                    <div className="feature-text-para position-relative mt-lg-4">
                      <p className="pe-xl-4">{greenGlobeDescription}</p>
                      {greenGlobeLearnMoreLink ? (
                        <div className="learnmore mt-xl-5 mt-4 pt-xl-2">
                          <Link
                            to="/coming-soon"
                            className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                          >
                            Learn More{" "}
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="ms-2"
                            />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row position-relative section-padding pb-0">
                <div className="col-md-6">
                  <div className="right-feature-wrap right-space ps-0">
                    <div className="top-head-text">
                      <h4 className="georgia text-uppercase">
                        {sustainableOperationHeading}
                      </h4>
                    </div>
                    <div className="feature-text-para position-relative">
                      <p>{sustainableOperationDescription}</p>
                      {sustainableOperationLearnMoreLink ? (
                        <div className="learnmore mt-xl-5 mt-4">
                          <Link
                            to="/coming-soon"
                            className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                          >
                            Learn More{" "}
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="ms-2"
                            />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-left-image">
                    {/* <img
                      className="w-100"
                      src={sustainableOperationImage}
                      alt={sustainableOperationImageAltTag}
                      title=""
                    /> */}
                    <Image
                      className="w-100"
                      src={sustainableOperationImage}
                      alt={sustainableOperationImageAltTag}
                      title=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <Sustainability
            greenSustain={greenSustain}
            greenBottomSustain={greenBottomSustain}
            images={images}
            bottomimages={bottomimages}
            showTopLink={false}
            showSustainPara={false}
            sustainAbility={greenInitiativeTitle}
            greenSustainImage={greenSustainImage}
            greenButtomSustainImage={greenButtomSustainImage}
            greenButtomSustainImageAltTag={greenButtomSustainImageAltTag}
            greenSustainImageAltTag={greenSustainImageAltTag}
          />
        </section>
      </div>
    </>
  );
}

export default SustainabilityPage;
