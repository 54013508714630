import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import offerImgOne from "../assets/images/offer-img-1.jpg";
import offerImgTwo from "../assets/images/offer-img-2.jpg";
import offerImgThree from "../assets/images/offer-img-3.jpg";
import offerImgFour from "../assets/images/offer-img-4.jpg";
import OfferSlider from "./OfferSlider";
import { getFeaturedOfferData } from "../services/dataServices";
import { useQuery } from "react-query";

function SpecialOffers({offersSlug ,
  slugOfDiningOffers="",
  slugOfRoomOffers="",
  slugOfSpaOffers=""
}) {
  const gallerySettings = {
    arrow: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let allFeaturedOffer = useQuery("getFeaturedOffer", getFeaturedOfferData);

  
  return (
    <section>
      <div className="special-offers section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hotel-heading-wrap d-flex align-items-center justify-content-lg-center justify-content-between position-relative">
                <h2 className="text-uppercase georgia heading-space mb-0 text-center">
                  Our Special offers
                </h2>
                {/* <Link
                  to={`/${offersSlug}`}
                  className="position-absolute end-0 top-0 text-dark text-decoration-none view-all mt-xl-3 mt-2"
                  title="Course Link"
                >
                  VIEW ALL{" "}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="ms-3 icon-font"
                  />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        {/* /container-fluid */}

        <div className="special-offers-slides mt-lg-5 mt-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <OfferSlider
                offersSlug={offersSlug}
                  gallerySettings={gallerySettings}                 
                  data={allFeaturedOffer}
                  slugOfDiningOffers={slugOfDiningOffers}
        slugOfRoomOffers={slugOfRoomOffers}
        slugOfSpaOffers={slugOfSpaOffers} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SpecialOffers;
