import React, { useContext, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExperienceAwait from "../../components/ExperienceAwait";
import akira from "../../assets/images/spa.svg";
import AkiraFeatures from "./AkiraFeatures";
import Glimpses from "../../components/Glimpses";
import { useQuery } from "react-query";
import { getPageData, getPageData1 } from "../../services/dataServices";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import Helmet from "../../components/Helmet";
import BannerImage from  "../../components/BannerImage"
function AkiraSpa({
  image = akira,
  isPreview,
  userId,
  sectionId,
  slugOfGallery,
}) {
  const sectionClass = "akira-welcome";

  const pageCode = "SAKIRASPA";

  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
console.log("pagesSectionData akiri",pagesSectionData)
  const bannerImageAltTag =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "AKIRASPA")[0]
      ?.section_elements.filter(
        (item) => item.field_label_code === "BANNERIMAGE"
      )[0]?.img_alt_tag ?? null;

  const bannerImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.page_template_code === "SAKIRASPA" &&
        item.section_code === "AKIRASPA" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data;

  const head = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "AKIRASPA")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data;

  const icon =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "AKIRASPA")[0]
      ?.section_elements.filter((item) => item.field_label_code === "ICON")[0]
      ?.data;

  const desc = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "AKIRASPA")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data;

  const image1 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE5"
    )[0]?.data;

  const image2 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE2"
    )[0]?.data;

  const image3 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE4"
    )[0]?.data;

  const image4 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE3"
    )[0]?.data;

  const image5 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE6"
    )[0]?.data;

  const image6 = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
    ?.section_elements.filter(
      (item) => item.field_label_code === "IMAGE1"
    )[0]?.data;

  const topLeftImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE1")[0]
      ?.img_alt_tag ?? null;

  const topRightImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE5")[0]
      ?.img_alt_tag ?? null;

  const bottomLeftImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE2")[0]
      ?.img_alt_tag ?? null;

  const bottomCenterImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE4")[0]
      ?.img_alt_tag ?? null;

  const topCenterImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE3")[0]
      ?.img_alt_tag ?? null;

  const bottomRightImagealt =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "GLIMPSES")[0]
      ?.section_elements.filter((item) => item.field_label_code === "IMAGE6")[0]
      ?.img_alt_tag ?? null;

  const subsection = pagesSectionData?.data?.data
    .filter((item) => item.page_template_code === "SAKIRASPA")[0]
    ?.section_details.filter(
      (item) => item.section_code === "AKIRASPA"
    )[0]?.subSectionData;

  const akiraPdf =
    pagesSectionData?.data?.data
      .filter((item) => item.page_template_code === "SAKIRASPA")[0]
      ?.section_details.filter((item) => item.section_code === "AKIRASPA")[0]
      ?.section_elements.filter(
        (item) => item.field_label_code === "MENUBUTTON"
      )[0]?.data ?? null;

  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  // const { setIsLoadingMain } = useContext(MyContext);
  const { isLoading } = pagesSectionData1;
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  // const handleError = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  
  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader/>;
  // }
  // else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              onLoad={handleImageLoad}
              onError={handleError}
            /> */}
             <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                ELE | NA SPA
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Ele | Na Spa</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <div className="akira-page-wrap">
        <ExperienceAwait
          akiraMenuPDF={akiraPdf}
          showMenu={true}
          sectionClass={sectionClass}
          showLargeheading={true}
          heading={head}
          para={desc}
          image={icon}
        />

        <section>
          <div className="akira-services-cards section-padding pt-0">
            <div className="container">
              {/* {akiraFeatureCardData.map(data => (
                                <AkiraFeatures
                                    key={data.id}
                                    akiraFeatureImg={data.akiraFeatureImg}
                                    akiraSmallText={data.akiraSmallText}
                                    akiraHeading={data.akiraHeading}
                                    akiraPara={data.akiraPara}
                                    akiraAccordionData={data.akiraAccordionData}
                                    showSmallText={data.showSmallText}
                                />
                            ))} */}
              {subsection?.map((item, index) => {
                const image = item?.elements.filter(
                  (item) => item.field_title_code === "IMAGE"
                )[0]?.data;

                const imageAltTag = item?.elements.filter(
                  (item) => item.field_title_code === "IMAGE"
                )[0]?.img_alt_tag;

                const title = item?.elements.filter(
                  (item) => item.field_title_code === "TITLE"
                )[0]?.data;
                const para = item?.elements.filter(
                  (item) => item.field_title_code === "DESCRIPTION"
                )[0]?.data;
                const heading = item?.elements.filter(
                  (item) => item.field_title_code === "HEADING"
                )[0]?.data;

                const accBody = item?.children.map((item, index) => item);

                if (heading === null) {
                  return;
                }
                return (
                  <AkiraFeatures
                    key={index}
                    akiraFeatureImg={image}
                    imageAltTag={imageAltTag}
                    akiraSmallText={title}
                    akiraHeading={heading}
                    akiraPara={para}
                    akiraAccordionData={accBody}
                    showSmallText={false}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </div>
      <Glimpses
        image1={image1}
        image2={image2}
        image3={image3}
        image4={image4}
        image5={image5}
        image6={image6}
        bottomLeftImagealt={bottomLeftImagealt}
        topRightImagealt={topRightImagealt}
        bottomCenterImagealt={bottomCenterImagealt}
        topCenterImagealt={topCenterImagealt}
        bottomRightImagealt={bottomRightImagealt}
        topLeftImagealt={topLeftImagealt}
        slugOfGallery={slugOfGallery}
      />
    </>
  );
}

export default AkiraSpa;
